<template>
  <div class="internal-communication-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="导入" permission="import" @click="importExcel"></v-button>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="姓名" v-model="searchParams.userName"></v-input>
        <v-input label="身份" v-model="searchParams.identity"></v-input>
        <v-input label="手机号" v-model="searchParams.mobileNum"></v-input>
        <v-select label="角色类别" v-model="searchParams.userType" :options="roleTypeOps"></v-select>
        <v-select2 label="角色归属" v-model="searchParams.orgId" :disabled="!searchParams.userType" v-bind="orgParams" :subjoin="{ roleType: searchParams.userType }"></v-select2>
        <v-input label="添加人" v-model="searchParams.inuser"></v-input>
        <v-select label="状态" v-model="searchParams.status" :options="statusOps"></v-select>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
        <v-button text="删除" type="text" permission="delete" @click="remove(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getExternalListURL, exportOutURL, getOrgURL, deleteURL } from './api'
import { statusMap, setStatusOps, setSourceOps, roleTypeMap, setRoleTypeOps } from './map'

export default {
  name: 'InternalCommunicationList',
  data () {
    return {
      searchUrl: getExternalListURL,
      exportUrl: exportOutURL,
      statusOps: setStatusOps(1),
      roleTypeOps: setRoleTypeOps(1),
      sourceOps: setSourceOps(1),
      orgParams: {
        searchUrl: getOrgURL,
        request: {
          text: 'orgName',
          value: 'orgId'
        }
      },
      searchParams: {
        userName: '',
        status: undefined,
        orgId: '',
        fromtDate: '',
        toDate: ''
      },
      headers: [
        {
          prop: 'userName',
          label: '姓名'
        },
        {
          prop: 'identity',
          label: '身份'
        },
        {
          prop: 'mobileNum',
          label: '手机号'
        },
        {
          prop: 'roleTypeName',
          label: '角色类别',
          formatter: row => roleTypeMap[row.userType]
        },
        {
          prop: 'orgName',
          label: '角色归属'
        },
        {
          prop: 'inuser',
          label: '添加人'
        },
        {
          prop: 'status',
          label: '状态',
          formatter: row => statusMap[row.status]
        },
        {
          prop: 'updateTime',
          label: '修改时间'
        }
      ]
    }
  },
  methods: {
    create () {
      this.$router.push({
        name: 'ExternalCommunicationForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'ExternalCommunicationForm',
        query: {
          id: row.id
        }
      })
    },
    importExcel () {
      this.$router.push({
        name: 'ExternalCommunicationImport'
      })
    },
    async remove (row) {
      let isConfirm = await this.$confirm('是否删除？')
      if (isConfirm) {
        let { status } = await this.$axios.post(`${deleteURL}?maillistId=${row.id}`)
        if (status - 0 === 100) {
          this.$refs.list.searchData()
        }
      }
    }
  }
}
</script>
