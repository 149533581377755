var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "internal-communication-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "export",
          searchUrl: _vm.searchUrl,
          exportUrl: _vm.exportUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "导入", permission: "import" },
                  on: { click: _vm.importExcel },
                }),
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.create },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "姓名" },
                  model: {
                    value: _vm.searchParams.userName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userName", $$v)
                    },
                    expression: "searchParams.userName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "身份" },
                  model: {
                    value: _vm.searchParams.identity,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "identity", $$v)
                    },
                    expression: "searchParams.identity",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "手机号" },
                  model: {
                    value: _vm.searchParams.mobileNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "mobileNum", $$v)
                    },
                    expression: "searchParams.mobileNum",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "角色类别", options: _vm.roleTypeOps },
                  model: {
                    value: _vm.searchParams.userType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userType", $$v)
                    },
                    expression: "searchParams.userType",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: {
                        label: "角色归属",
                        disabled: !_vm.searchParams.userType,
                        subjoin: { roleType: _vm.searchParams.userType },
                      },
                      model: {
                        value: _vm.searchParams.orgId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "orgId", $$v)
                        },
                        expression: "searchParams.orgId",
                      },
                    },
                    "v-select2",
                    _vm.orgParams,
                    false
                  )
                ),
                _c("v-input", {
                  attrs: { label: "添加人" },
                  model: {
                    value: _vm.searchParams.inuser,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "inuser", $$v)
                    },
                    expression: "searchParams.inuser",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "状态", options: _vm.statusOps },
                  model: {
                    value: _vm.searchParams.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "status", $$v)
                    },
                    expression: "searchParams.status",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "编辑", type: "text", permission: "update" },
                  on: {
                    click: function ($event) {
                      return _vm.edit(scope.row)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: { text: "删除", type: "text", permission: "delete" },
                  on: {
                    click: function ($event) {
                      return _vm.remove(scope.row)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }